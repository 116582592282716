import React from "react";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from "react-router-dom";

import { useAuth } from "@/contexts";
import {
  PublicRoute,
  AuthRoute,
  PublicLayout,
  PrivateLayout,
} from "@/components";
import { PATH } from "@/assets";

import * as P from "pages";
import {
  ClientTypeGuard,
  Footer,
  GoogleMapLayout,
  Header,
  ManagerGuard,
  Navbar,
} from "components";
import type { CompanyType } from "types";

interface RouterProps {
  children: React.ReactNode;
}

export default function Router({ children }: RouterProps) {
  const { auth } = useAuth();

  const orderCreation = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierOrderCreation />;

      case "forwarder":
        return <P.ForwarderOrderCreation />;

      case "forwardercarrier":
        return <P.ForwarderCarrierOrderCreation />;

      case "shipper":
        return <P.ShipperOrderCreation />;
    }
  };

  const orderManagement = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierOrderManagement />;
      case "forwarder":
        return <P.ForwarderOrderManagement />;
      case "forwardercarrier":
        return <P.ForwarderCarrierOrderManagement />;
      case "shipper":
        return <P.ShipperOrderManagement />;
    }
  };

  const orderManagementDetail = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierOrderManagementDetail />;

      case "forwarder":
        return <P.ForwarderOrderManagementDetail />;

      case "forwardercarrier":
        return <P.ForwarderCarrierOrderManagementDetail />;

      case "shipper":
        return <P.ShipperOrderManagementDetail />;
    }
  };

  const orderManagementMap = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierOrderMap />;

      case "forwardercarrier":
        return <P.ForwarderCarrierOrderMap />;

      case "shipper":
        return <P.ShipperOrderMap />;
    }
  };

  const orderManagementAllocation = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierOrderManagementAllocation />;

      case "forwardercarrier":
        return <P.ForwarderCarrierOrderManagementAllocation />;
    }
  };

  const quotation = (clientType?: CompanyType) => {
    const CSVN_ID_UUID = "dce5a759-7612-4778-aae3-4eff3dbbc231";

    if (auth.companyId === CSVN_ID_UUID) {
      return <P.NotFound />;
    }

    switch (clientType) {
      case "carrier":
        return <P.CarrierQuotation />;

      case "forwarder":
        return <P.ForwarderQuotation />;

      case "forwardercarrier":
        return <P.ForwarderCarrierQuotation />;

      case "shipper":
        return <P.ShipperQuotation />;
    }
  };

  const quotationDetail = (clientType?: CompanyType) => {
    const CSVN_ID_UUID = "dce5a759-7612-4778-aae3-4eff3dbbc231";

    if (auth.companyId === CSVN_ID_UUID) {
      return <P.NotFound />;
    }

    switch (clientType) {
      case "carrier":
        return <P.CarrierQuotationDetail />;

      case "forwarder":
        return <P.ForwarderQuotationDetail />;

      case "forwardercarrier":
        return <P.ForwarderCarrierQuotationDetail />;

      case "shipper":
        return <P.ShipperQuotationDetail />;
    }
  };

  const quotationSend = (clientType?: CompanyType) => {
    switch (clientType) {
      case "forwarder":
        return <P.ForwarderQuotationSend />;

      case "forwardercarrier":
        return <P.ForwarderCarrierQuotationSend />;
    }
  };

  const monitoring = (clientType?: CompanyType) => {
    switch (clientType) {
      case "shipper":
        return <P.ShipperMonitoring />;

      case "forwardercarrier":
        return <P.ForwarderCarrierMonitoring />;

      case "carrier":
        return <P.CarrierMonitoring />;
    }
  };

  const settlement = (clientType?: CompanyType) => {
    const CSVN_ID_UUID = "dce5a759-7612-4778-aae3-4eff3dbbc231";

    if (auth.companyId === CSVN_ID_UUID) {
      return <P.NotFound />;
    }

    switch (clientType) {
      case "carrier":
        return <P.CarrierSettlement />;

      case "forwarder":
        return <P.ForwarderSettlement />;

      case "forwardercarrier":
        return <P.ForwarderCarrierSettlement />;

      case "shipper":
        return <P.ShipperSettlement />;
    }
  };

  const settlementDetail = (clientType?: CompanyType) => {
    const CSVN_ID_UUID = "dce5a759-7612-4778-aae3-4eff3dbbc231";

    if (auth.companyId === CSVN_ID_UUID) {
      return <P.NotFound />;
    }

    switch (clientType) {
      case "carrier":
        return <P.CarrierSettlementDetail />;

      case "forwarder":
        return <P.ForwarderSettlementDetail />;

      case "forwardercarrier":
        return <P.ForwarderCarrierSettlementDetail />;

      case "shipper":
        return <P.ShipperSettlementDetail />;
    }
  };

  const settingsPlanEdit = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierCompanyPlanEdit />;

      case "forwarder":
        return <P.ForwarderCompanyPlanEdit />;

      case "forwardercarrier":
        return <P.ForwarderCarrierCompanyPlanEdit />;
    }
  };

  const settingsLicense = (clientType?: CompanyType) => {
    switch (clientType) {
      case "carrier":
        return <P.CarrierCompanyLicenseRegistration />;

      case "forwarder":
        return <P.ForwarderCompanyLicenseRegistration />;

      case "forwardercarrier":
        return <P.ForwarderCarrierCompanyLicenseRegistration />;

      case "shipper":
        return <P.ShipperCompanyLicenseRegistration />;
    }
  };

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route element={children}>
        <Route path="*" element={<P.NotFound />} />
        <Route element={<PublicRoute path={PATH.ORDER_MANAGEMENT} />}>
          <Route element={<PublicLayout />}>
            <Route path={PATH.ROOT} element={<P.Login />} />
            <Route path={PATH.EXPIRED} element={<P.Expired />} />
          </Route>
        </Route>
        <Route element={<AuthRoute />}>
          <Route
            element={
              <PrivateLayout
                header={<Header />}
                navbar={<Navbar />}
                footer={<Footer />}
              />
            }
          >
            <Route
              path={PATH.ORDER_CREATION}
              element={orderCreation(auth.clientType)}
            />
            <Route element={<GoogleMapLayout />}>
              <Route element={<ClientTypeGuard />}>
                <Route
                  path={PATH.MONITORING}
                  element={monitoring(auth.clientType)}
                />
              </Route>
              <Route element={<ClientTypeGuard />}>
                <Route path={PATH.ALLOCATION}>
                  <Route path="" element={<P.Allocation />} />
                  <Route path="create" element={<P.AllocationCreate />} />
                  <Route path="edit" element={<P.AllocationEdit />} />
                </Route>
              </Route>
              <Route path={PATH.ORDER_MANAGEMENT}>
                <Route
                  path="map"
                  element={orderManagementMap(auth.clientType)}
                />
                <Route
                  path="allocation"
                  element={orderManagementAllocation(auth.clientType)}
                />
              </Route>
            </Route>
            <Route path={PATH.QUOTATION}>
              <Route path="" element={quotation(auth.clientType)} />
              <Route path=":id" element={quotationDetail(auth.clientType)} />
              <Route path="send" element={quotationSend(auth.clientType)} />
            </Route>
            <Route path={PATH.ORDER_MANAGEMENT}>
              <Route path="" element={orderManagement(auth.clientType)} />
              <Route
                path=":id"
                element={orderManagementDetail(auth.clientType)}
              />
            </Route>
            <Route element={<ClientTypeGuard />}>
              <Route path={PATH.PROPERTY}>
                <Route path="" element={<P.Property />} />
                <Route path="driver">
                  <Route path="create" element={<P.DriverCreate />} />
                  <Route path=":id" element={<P.DriverDetail />} />
                  <Route
                    path="allocation/:id"
                    element={<P.DriverAllocation />}
                  />
                </Route>
                <Route path="truck">
                  <Route path="create" element={<P.TruckCreate />} />
                  <Route path=":id" element={<P.TruckDetail />} />
                  <Route
                    path="allocation/:id"
                    element={<P.TruckAllocation />}
                  />
                </Route>
                <Route path="garage">
                  <Route path="create" element={<P.GarageCreate />} />
                  <Route path="edit" element={<P.GarageEdit />} />
                </Route>
              </Route>
            </Route>
            <Route path={PATH.SETTLEMENT}>
              <Route path="" element={settlement(auth.clientType)} />
              <Route path=":id" element={settlementDetail(auth.clientType)} />
            </Route>
            <Route path={PATH.SETTINGS}>
              <Route path="" element={<P.Settings />} />
              <Route path="account" element={<P.Account />} />
              <Route path="company">
                <Route path="" element={<P.Company />} />
                <Route path="plan" element={<P.CompanyPlan />} />
                <Route
                  path="planEdit"
                  element={settingsPlanEdit(auth.clientType)}
                />
                <Route
                  path="license"
                  element={settingsLicense(auth.clientType)}
                />
              </Route>
              <Route element={<ManagerGuard />}>
                <Route path="member">
                  <Route path="" element={<P.Member />} />
                  <Route path="create" element={<P.CreateMember />} />
                  <Route path="edit/:id" element={<P.EditMember />} />
                </Route>
              </Route>
              <Route path="notice">
                <Route path="" element={<P.Notice />} />
                <Route path=":id" element={<P.NoticeDetail />} />
              </Route>
              <Route path="support">
                <Route path="" element={<P.Support />} />
                <Route path=":id" element={<P.SupportDetail />} />
                <Route path="create" element={<P.CreateSupport />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>,
    ),
  );
  return <RouterProvider router={router} />;
}
