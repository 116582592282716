export const FILTER_CONDITION_INPUT_MAX_LENGTH = {
  orderNumber: 15,
  name: 100,
  location: 100,
  driverName: 100,
  driverPhone: 15,
  // NOTE: 자산관리-차고지 SearchInput의 제한 글자가 100자라 수정하였음. 사용처마다 확인이 필요해보임
  searchInput: 100,
  subject: 50,
  phone: 15,
  code: 10,
} as const;

// NOTE: 임의 주소 디폴트값: 호치민 시청
export const DEFAULT_MAP_COORD = {
  lng: 106.700972,
  lat: 10.776561,
} as const;

export const DRAG_STATUS = Object.freeze({
  START: "start",
  STOP: "stop",
  ENTER: "enter",
  LEAVE: "leave",
});

export const convertMB = (num: number) => num * 1048576; // NOTE: 1MB: 1048576byte

export const PRIVACY_POLICY = "https://www.cocotruck.ai/client/privacy";
export const TERMS_CONDITIONS = "https://www.cocotruck.ai/client/terms";

export const FILE_DESC = "File format: jpg, jpeg, png or pdf (Limit 5MB)";
export const IMG_DESC = "File format: jpg, jpeg or png (Limit 5MB)";
