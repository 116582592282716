import axios from "axios";

import { getS3FileFormData } from "@/utils";
import type {
  LinkPlayerQueryModel,
  LinkPlayerServerModel,
  S3PresignedServerModel,
} from "@/types";

import { ax } from "apis/axios";
import type { TrucksTypeOptionServerModel } from "types";

export const getTruckTypeOptionAPI = async () => {
  const { data } = await ax.get<TrucksTypeOptionServerModel[]>(
    "/property/truck/option",
  );

  return data;
};

export const getLinkPlayerAPI = async (req: LinkPlayerQueryModel) => {
  const { data } = await ax.get<LinkPlayerServerModel>("/link/player", {
    params: req.query,
  });

  return data;
};

export const postS3PresignedUrlAPI = async (
  key: string,
  file: File,
): Promise<any> => {
  const res: S3PresignedServerModel = await ax.post(`/presigned/${key}`);

  const formData = getS3FileFormData(res, file);

  return await axios.post(res.data.url, formData);
};

export const getS3PresignedUrlAPI = async (
  s3FileKey: string,
): Promise<string> => {
  if (!s3FileKey) return "";

  const { data } = await ax.get(`/presigned/${s3FileKey}`);

  return data;
};

// TODO: 추후 삭제 필요
export const getPresignedUrlAPI = (url: string | null) => {
  if (!url) return;
  return ax.get(`common/presigned/${url}`).then((res) => res?.data?.url);
};

// TODO: 추후 삭제 필요
export const postPresignedUrlAPI = async (
  s3Key: string | undefined,
  file: File,
): Promise<any> => {
  if (!file || !s3Key) return;

  const postPresigned: S3PresignedServerModel = await ax.post(
    `/common/presigned/${s3Key}`,
  );
  const { url, fields } = postPresigned.data;
  const formData = new FormData();
  for (const [key, value] of Object.entries(fields)) {
    formData.append(key, value);
  }

  formData.append("Content-type", file.type);
  formData.append("file", file);

  await axios.post(url, formData);
};
