import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { ExternalLink } from "@/components";
import { PRIVACY_POLICY, TERMS_CONDITIONS } from "@/constants";

import * as S from "./Footer.styled";

const Footer = () => {
  const { t } = useTranslation();

  const location = useLocation();
  // TODO: footer를 숨김 필요한 페이지는 매번 추가해주어야 함, 리팩토링 필요
  const isView =
    ["tracking", "allocation", "monitoring"].includes(
      location.pathname.split("/")[1],
    ) || ["map", "allocation"].includes(location.pathname.split("/")[2]);

  if (isView) return null;

  return (
    !isView && (
      <S.Root>
        <S.Ul>
          <S.Li>
            {t(
              `Customer service center: KO +82)2-564-0309 (9:00-18:00), VN +84)83-373-1900 (9:00-18:00)`,
            )}
          </S.Li>
          <S.Li>E-mail: contact@coconutsilo.com</S.Li>
          <S.Li>
            <ExternalLink
              css={S.link}
              href={TERMS_CONDITIONS}
              variant="ghostNoUnderlineGray"
            >
              {t("Terms and conditions")}
            </ExternalLink>
          </S.Li>
          <S.Li>
            <ExternalLink
              css={S.link}
              href={PRIVACY_POLICY}
              variant="ghostNoUnderlineGray"
            >
              {t("Privacy policy")}
            </ExternalLink>
          </S.Li>
        </S.Ul>
      </S.Root>
    )
  );
};

export default Footer;
