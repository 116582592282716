import styled from "@emotion/styled";
import { css, Theme } from "@emotion/react";

export const Root = styled.footer`
  ${({ theme }) => css`
    width: ${`calc(100% - ${theme.size.NAV_WIDTH})`};
    margin-left: ${theme.size.NAV_WIDTH};
    padding: 40px 0 20px 0;
    background-color: ${theme.color.gray_00};
  `}
`;

export const Ul = styled.ul`
  display: flex;
  justify-content: center;
`;

const mixin_text = (theme: Theme) => css`
  ${theme.font.regular_12};
  color: ${theme.color.gray_60};
`;

export const Li = styled.li`
  ${({ theme }) => css`
    ${mixin_text(theme)};
    padding: 0 12px;

    &:not(:last-child) {
      border-right: 1px solid ${theme.color.gray_40};
    }
  `}
`;

export const link = (theme: Theme) => css`
  ${mixin_text(theme)};
`;
