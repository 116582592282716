import React from "react";
import { Outlet } from "react-router-dom";

import * as S from "./PublicLayout.styled";

const PublicLayout = () => {
  return (
    <S.Root>
      <S.Wrapper>
        <S.CustomLoginLogo />
      </S.Wrapper>
      <S.Wrapper>
        <Outlet />
      </S.Wrapper>
      <S.CustomIfDesignIcon />
    </S.Root>
  );
};

export default PublicLayout;
