import styled from "@emotion/styled";

import { CocotruckLogoIcon, IfDesignIcon } from "@/assets";
import bgImg from "@/assets/image/login_background.jpg";

export const Root = styled.section`
  position: relative;
  min-width: 1200px;
  width: 100vw;
  min-height: 600px;
  height: 100vh;
  display: flex;
  background: center / cover no-repeat url(${bgImg});

  ::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
  }
`;

export const Wrapper = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;

export const CustomLoginLogo = styled(CocotruckLogoIcon)`
  width: 348px;
  height: 158px;
  fill: white;
`;

export const CustomIfDesignIcon = styled(IfDesignIcon)`
  position: absolute;
  bottom: 40px;
  right: 40px;
  z-index: 2;
`;
